import { Injectable, Injector } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { UnzipService } from './unzip.service';

@Injectable()
export class SharedService {
  private sharedData: Subject<any> = new Subject<any>();
  sharedData$: Observable<any> = this.sharedData.asObservable();
  private activitySubject = new Subject<void>();
  private inactivityTimer: any;
  private readonly INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes
  public islogout: boolean = false;
  public isSessionExpired: boolean = false;

  constructor(
    private authService: MsalService,
    private dialogref: MatDialog,
    private injector: Injector
  ) {}

  setData(updatedData: any) {
    this.sharedData.next(updatedData);
  }
  public email: any;
  getActivityObservable(): Observable<void> {
    return this.activitySubject.asObservable();
  }

  resetTimer(): void {
    clearTimeout(this.inactivityTimer);
    this.inactivityTimer = setTimeout(() => {
      console.log(this.islogout);
      if (!this.islogout) {
        this.isSessionExpired = true;
        this.dialogref.open(ErrorPopupComponent);
        setTimeout(() => {
          this.sessionlogout();
        }, 3000);
      }
    }, this.INACTIVITY_TIMEOUT);
  }
  sessionlogout() {
    const logoutCall = this.injector.get(UnzipService);
    logoutCall.logout().subscribe(
      (data: any) => {
        console.log(data);
        this.authService.logoutRedirect({
          // postLogoutRedirectUri: environment.postLogoutUrl,
          postLogoutRedirectUri: null,
        });
      },
      (err) => {
        console.log(err);
      }
    );
    // const logoutUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https://insightsuat.lgtindia.in/`;
    // window.location.href = logoutUrl;
  }
}
