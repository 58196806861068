import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { UnzipService } from '../unzip.service';

@Component({
  selector: 'app-accesspopup',
  templateUrl: './accesspopup.component.html',
  styleUrls: ['./accesspopup.component.scss'],
})
export class AccesspopupComponent {
  constructor(
    private authService: MsalService,
    private unZipService: UnzipService
  ) {}
  Closepopup() {
    this.unZipService.logout().subscribe(
      (data: any) => {
        console.log(data);
        // this.authService.logoutRedirect({ postLogoutRedirectUri: environment.postLogoutUrl });
        this.authService.logoutRedirect({ postLogoutRedirectUri: null });
      },
      (err) => {
        console.log(err);
        // this.authService.logoutRedirect({ postLogoutRedirectUri: environment.postLogoutUrl });
        this.authService.logoutRedirect({ postLogoutRedirectUri: null });
      }
    );
  }
}
