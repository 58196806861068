import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, catchError, throwError, timeout } from 'rxjs';
import { Commonconfig } from './config/common-config';
import { SharedService } from './shared.service';
import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private serverUrl = this.config.mfiturl;
  private showError = true;

  constructor(private httpClient: HttpClient, public config: Commonconfig,private _sharedService:SharedService,private dialogref: MatDialog) { }

  upload(file: File): Observable<HttpEvent<any>> {
    this._sharedService.islogout=true;
    this._sharedService.resetTimer();

    const timeoutDuration = 15 * 60 * 1000;
    


    const formData: FormData = new FormData();

    formData.append('file', file);

    formData.append('password', "Blue*135");


    const request = new HttpRequest('POST', `${this.serverUrl}`, formData, {
      reportProgress: true,
      responseType: 'arraybuffer',

    },);

    return this.httpClient.request(request).pipe(
      timeout(timeoutDuration),
      catchError((error) => {
        if (error.name === 'TimeoutError') {
          if(this.showError){
            this.showError=false;
          this.dialogref.open(ErrorPopupComponent );
          }
        }
        return throwError(error);
      })
    );
  }

}
