import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DownloadComponent } from './download/download.component';

const routes: Routes = [
 
  {
    path:'',
    component:DashboardComponent
  },
  {path:'DownloadReport',
  component: DownloadComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      initialNavigation: 'enabledBlocking',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }