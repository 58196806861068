import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Commonconfig {
  mfiturl =
    'https://development.mfit.co.in/rest/file/scrapeToFile?apiKey=83b47129-70f9-4651-ab0d-cd2e8fb67fd5&fmt=zip';
  // restapiurl = 'https://insightsuat.lgtindia.in/api/Slide/';
  restapiurl = 'https://insight-api.icaniotech.com/api/Slide/';
  validateUrl = 'https://insight-api.icaniotech.com/api/ParserIntegration/';
  heldawayapiurl = 'https://insight-api.icaniotech.com/api/HeldAway/';
  Tokenapiurl = 'https://insight-api.icaniotech.com/api/Account/Login';
  // Logoutapiurl = 'https://insightsuat.lgtindia.in/api/Account/Logout';
  S3Accesskeyid = 'AKIAWOUNCEXR5CEMXAWY';
  S3SecretaccessKey = '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';
  S3bucket = 'insightlgt';
  s3Folder = 'UAT';
  // version = '2.19 (Evaluation Environment)';
  // Logoutapiurl = 'https://prj-one-ui.icaniotech.com/';
  // Logoutapiurl = 'http://localhost:4200';
  Logoutapiurl = 'https://insight-api.icaniotech.com/api/Account/Logout';
  version = 'UAT 3.07 (Evaluation Environment)';
}
