import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupService } from '../popup.service';


@Component({
  selector: 'app-modalpopup',
  templateUrl: './modalpopup.component.html',
  styleUrls: ['./modalpopup.component.scss']
})
export class ModalpopupComponent implements OnInit {

  constructor(private dialogref: MatDialog, public popupservice: PopupService) { }

  ngOnInit(): void {
    console.log(this.popupservice.progressInfos);
  }

  Close() {
    this.popupservice.uploadStatus = '';
    this.dialogref.closeAll();

  }
  loadercall() {

    this.popupservice.showloader = false;
  }
}