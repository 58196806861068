import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private sharedprogressinfo: Subject<any> = new Subject<any>();
  sharedprogressinfo$: Observable<any> = this.sharedprogressinfo.asObservable();
  public ppterrormessage: any;
  public progressInfos: any[] = [];
  public passfilename: any;
  public allfiles: any;
  public showloader: boolean = false;
  public uploadStatus: string = '';
  public idx: any;
  public isexcelfile: any = 0;

  public completedidx: number = 0;

  public uploadCompletedFilesStatus: string = '';
  public allSelectedFiles: any[] = [];

  constructor() {}

  getData(passData: any) {
    this.sharedprogressinfo.next(passData);
  }
}
