<div [ngStyle]="{ display: isHeader ? 'block' : 'none' }">
  <div class="toolbar-spacer"></div>
  <!-- <div *ngIf="isUserLoggedIn"> -->
  <div>
    <mat-toolbar class="header">
      <!-- <mat-toolbar-row class="justify-content-between"> -->

      <label style="display: flex; align-items: center">
        <div class="logo">
          <img
            class="image"
            src="/assets/images/LGT_logo.png"
            alt="Company Logo"
          />
        </div>
        <h1 class="text">WealthIndia</h1>
      </label>
      <!-- <div class="spacer"></div> -->
      <div class="row">
        <button
          mat-button
          [matMenuTriggerFor]="menu"
          class="user mt-2 d-flex align-items-center"
        >
          <mat-icon>account_circle </mat-icon>
          {{ userName }}
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="logout()" mat-menu-item>
            <mat-icon>exit_to_app</mat-icon>
            Logout
          </button>
        </mat-menu>
      </div>
      <!-- </mat-toolbar-row> -->
    </mat-toolbar>

    <mat-divider></mat-divider>
  </div>
  <router-outlet></router-outlet>
  <div *ngIf="isUserLoggedIn" style="text-align: center; margin-top: 8px">
    <mat-divider
      style="border-top-width: 1px; border-color: #00498f; margin-bottom: 7px"
    ></mat-divider>
    <span>{{ this.version }} </span>
  </div>
</div>
