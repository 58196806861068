import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { PopupService } from '../popup.service';
import { UnzipService } from '../unzip.service';

@Component({
  selector: 'app-successpopup',
  templateUrl: './successpopup.component.html',
  styleUrls: ['./successpopup.component.scss']
})
export class SuccesspopupComponent {

  constructor(private dialogref: MatDialog, public popupservice: PopupService, public unzipservice: UnzipService) { }


  Closepopup() {

    this.dialogref.closeAll();

  }
}
