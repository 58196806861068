import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-validatepopup',
  templateUrl: './validatepopup.component.html',
  styleUrls: ['./validatepopup.component.scss']
})
export class ValidatepopupComponent {

  public showPopup:boolean=false;
  public message:string="";


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DashboardComponent>,
    private _dialog: MatDialog,
    public _sharedService:SharedService
  ) {
    if (data) {
this.showPopup = data.showPopup;
this.message = data.popupMessageShown;
    }}

    onClickContinue(){
      this.dialogRef.close(true);
    }

  Closepopup() {
     this.dialogRef.close(false);
  }

}
