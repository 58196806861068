import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { catchError, map, Observable, throwError, timeout } from 'rxjs';
import { Commonconfig } from './config/common-config';
import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from './shared.service';
// import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class UnzipService {
  readonly serverUrl = this.config.restapiurl;
  readonly excelUrl = this.config.validateUrl;
  readonly heldawayserverUrl = this.config.heldawayapiurl;
  readonly TokenUrl = this.config.Tokenapiurl;
  readonly logoutUrl = this.config.Logoutapiurl;

  constructor(
    private http: HttpClient,
    public config: Commonconfig,
    private dialogref: MatDialog,
    private _sharedService: SharedService // private logger: LoggerService
  ) {}
  public status: any;
  token: any = localStorage.getItem('token');
  userName: any = localStorage.getItem('username');

  unzipfiles(file: string): Observable<any> {
    this._sharedService.islogout = true;
    this._sharedService.resetTimer();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.token,
      }),
      params: { filename: file },
    };
    console.log('Zip File');
    return this.http.post<string>(
      this.serverUrl + 'UnZipFile',
      null,
      httpOptions
    );
  }

  generateppt(
    file: string,
    reportname: string,
    amtdisplay: string,
    rdprecision: any,
    rdAnnxure: any,
    email: any,
    heldaway: any
  ): Observable<any> {
    this._sharedService.islogout = true;
    this._sharedService.resetTimer();

    const httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.token,
      }),

      responseType: 'text',
      params: {
        OutputFilename: file,
        Reportname: reportname,
        Amtdisplay: amtdisplay,
        precision: rdprecision,
        Annxure: rdAnnxure,
        Email: email,
        Heldaway: heldaway,
      },
    };
    // this.logger.log('HTTP options:', httpOptions); // Example log
    return this.http.post<any>(
      this.serverUrl + 'GenerateReport',
      null,
      httpOptions
    );
  }

  heldawaygeneratereport(
    file: string,
    reportname: string,
    amtdisplay: string,
    rdprecision: any,
    rdAnnxure: any,
    email: any,
    heldaway: any
  ): Observable<any> {
    this._sharedService.islogout = true;
    this._sharedService.resetTimer();

    const httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.token,
      }),

      responseType: 'text',
      params: {
        OutputFilename: file,
        Reportname: reportname,
        Amtdisplay: amtdisplay,
        precision: rdprecision,
        Annxure: rdAnnxure,
        Email: email,
        Heldaway: heldaway,
      },
    };

    return this.http.post<any>(
      this.heldawayserverUrl + 'GenerateReport',
      null,
      httpOptions
    );
  }

  generateToken(userName: any): Observable<any> {
    let userData = {
      username: userName,
    };
    const httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),

      responseType: 'text',
    };
    console.log(this.TokenUrl, userData, httpOptions, 'ggggggggggg');
    return this.http.post<any>(this.TokenUrl, userData, httpOptions);
  }

  S3excelupload(Filetoupload: File, Filename: any): Observable<any> {
    this._sharedService.islogout = true;
    this._sharedService.resetTimer();

    const formData: FormData = new FormData();

    formData.append('file', Filetoupload);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Disposition': 'multipart/form-data',
        Authorization: 'Bearer ' + this.token,
      }),
      params: { filename: Filename },
    };

    return this.http.post<any>(
      this.serverUrl + 'Uploadexcel',
      formData,
      httpOptions
    );
  }

  ValidateExcelupload(filetoupload: File): Observable<any> {
    this._sharedService.islogout = true;

    this._sharedService.resetTimer();

    const timeoutDuration = 1 * 60 * 1000;
    const formData: FormData = new FormData();

    formData.append('sliderfile', filetoupload, filetoupload.name);

    const httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Disposition': 'multipart/form-data',
        Authorization: 'Bearer ' + this.token,
      }),
    };

    return this.http.post<any>(
      this.serverUrl + 'Validateexcel',
      formData,
      httpOptions
    );
    // .pipe(
    //   timeout(timeoutDuration),
    //   catchError((error) => {
    //     if (error.name === 'TimeoutError') {
    //       this.dialogref.open(ErrorPopupComponent );
    //     }
    //     return throwError(error);
    //   })
    // );
  }

  HeldAwayValidateExcelupload(filetoupload: File): Observable<any> {
    this._sharedService.islogout = true;

    this._sharedService.resetTimer();

    const timeoutDuration = 1 * 60 * 1000;
    const formData: FormData = new FormData();

    formData.append('sliderfile', filetoupload, filetoupload.name);

    const httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Disposition': 'multipart/form-data',
        Authorization: 'Bearer ' + this.token,
      }),
    };

    return this.http.post<any>(
      this.serverUrl + 'HeldawayValidateExcel',
      formData,
      httpOptions
    );

    // .pipe(
    //   timeout(timeoutDuration),
    //   catchError((error) => {
    //     if (error.name === 'TimeoutError') {
    //       this.dialogref.open(ErrorPopupComponent );
    //     }
    //     return throwError(error);
    //   })
    // );
  }

  HeldAwayValidatePPT(payload: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.token,
      }),
    };

    return this.http.post<any>(
      this.excelUrl + 'HeldawayValidateExcel',
      payload,
      httpOptions
    );
  }

  download(Docname: any, token: string): Observable<any> {
    this._sharedService.islogout = true;

    const contentTypes = [
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    const contentTypeHeader = contentTypes.join(', ');
    const httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': contentTypeHeader,
        Authorization: 'Bearer ' + this.token,
      }),

      responseType: 'arraybuffer',
      params: { documentName: Docname },
    };
    return this.http.get<any>(this.serverUrl + 'DownloadReport', httpOptions);
  }

  mfitlog(filename: string, activitystatus: string): Observable<any> {
    const httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.token,
      }),

      responseType: 'text',
      params: { Filename: filename, ActivityStatus: activitystatus },
    };
    return this.http.post<any>(this.serverUrl + 'MfitLog', null, httpOptions);
  }

  logout(): Observable<any> {
    const httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.token,
      }),

      responseType: 'text',
    };
    return this.http.post<any>(this.logoutUrl, null, httpOptions);
  }

  S3fileupload(Filetoupload: File, Filename: any): Observable<any> {
    this._sharedService.islogout = true;
    this._sharedService.resetTimer();

    const formData: FormData = new FormData();

    formData.append('file', Filetoupload);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Disposition': 'multipart/form-data',
        Authorization: 'Bearer ' + this.token,
      }),
      params: { filename: Filename },
    };

    return this.http.post<any>(
      this.serverUrl + 'UploadFiles',
      formData,
      httpOptions
    );
  }
}
