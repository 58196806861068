import { Component, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AzureAdDemoService } from './azure-ad-demo.service';
import { SharedService } from './shared.service';
import { Commonconfig } from './config/common-config';
import { UnzipService } from './unzip.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title(title: any) {
    throw new Error('Method not implemented.');
  }
  isUserLoggedIn: boolean = false;
  userName?: string = '';
  isHeader: boolean = false;

  public version: any;
  private readonly _destroy = new Subject<void>();
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private router: Router,
    private msalBroadCastService: MsalBroadcastService,
    public config: Commonconfig,
    private authService: MsalService,
    private azureAdDemoSerice: AzureAdDemoService,
    private sharedService: SharedService,
    private unZipService: UnzipService
  ) {}
  ngOnInit(): void {
    this.isHeader = localStorage.getItem('token') ? true : false;
    console.log(this.isHeader, 'TTTTTTTTTTt');

    // Simulate setting token (e.g., after login)

    this.authService.handleRedirectObservable().subscribe({
      next: (result) => {
        console.log('Token:', result);
        if (result) {
          console.log('Token:', result);
          this.updateUserDetails();
          // window.location.replace('/');
          window.location.reload();
        }
      },
      error: (error) => {
        console.error(error, 'error');
      },
    });

    this.version = this.config.version;
    if (this.authService.instance.getAllAccounts().length === 0) {
      this.login();
    } else {
      this.msalBroadCastService.inProgress$
        .pipe(
          filter(
            (interactionStatus: InteractionStatus) =>
              interactionStatus == InteractionStatus.None
          ),
          takeUntil(this._destroy)
        )
        .subscribe((x) => {
          this.isUserLoggedIn =
            this.authService.instance.getAllAccounts().length > 0;

          if (this.isUserLoggedIn) {
            console.log('object');
            this.userName = this.authService.instance.getAllAccounts()[0].name;
            console.log(this.userName, 'UUUU');
            this.sharedService.email =
              this.authService.instance.getAllAccounts()[0].username;
            localStorage.setItem('username', this.sharedService.email);
            // localStorage.setItem('emailId', this.sharedService.email);
            this.sharedService.setData(this.userName);
          }
          this.azureAdDemoSerice.isUserLoggedIn.next(this.isUserLoggedIn);
        });
    }
    this.sharedService.resetTimer();
  }

  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete();
  }
  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
    this.sharedService.resetTimer();
  }

  // logout() {
  //   const logoutUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https://insightsuat.lgtindia.in/`;
  //   window.location.href = logoutUrl;
  // }
  logout() {
    this.unZipService.logout().subscribe(
      (data: any) => {
        console.log(data, 'logged out');
        this.authService.logoutRedirect({
          // postLogoutRedirectUri: environment.postLogoutUrl,
          postLogoutRedirectUri: null,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // New Code
  private updateUserDetails() {
    this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;

    if (this.isUserLoggedIn) {
      this.userName = this.authService.instance.getAllAccounts()[0].name;
      this.sharedService.email =
        this.authService.instance.getAllAccounts()[0].username;
      localStorage.setItem('username', this.sharedService.email);
      this.sharedService.setData(this.userName);
      this.azureAdDemoSerice.isUserLoggedIn.next(this.isUserLoggedIn);
    }
  }
}
