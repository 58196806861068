import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TodoService {
  constructor(private http: HttpClient) {}

  //Family Names GET Call

  getFamilyNames(
    advisorName: string,
    portfolioTypes: boolean
  ): Observable<any> {
    let ApiUrl1 = `https://insight-api.icaniotech.com/api/ParserIntegration/familyNames?advisorName=${advisorName}&excludeHeldAway=${portfolioTypes}`;
    let httpParams = new HttpParams().set('advisorName', advisorName);
    return this.http.get<any>(ApiUrl1);
  }

  // Generate PPT Report Post Call
  private pptUrl =
    'https://insight-api.icaniotech.com/api/ParserIntegration/generate-report';

  generateReport(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(this.pptUrl, payload, { headers: headers });
  }

  //Generate PPT Report Proceed Call
}
