import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AzureAdDemoService } from './azure-ad-demo.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { UploadService } from './upload.service';
import { FormsModule } from '@angular/forms';
import { SharedService } from './shared.service';
import { ModalpopupComponent } from './modalpopup/modalpopup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { SuccesspopupComponent } from './successpopup/successpopup.component';
import { MatCardModule } from '@angular/material/card';
import { Commonconfig } from './config/common-config';
import { DownloadComponent } from './download/download.component';
import { environment } from 'src/environments/environment';
import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { AccesspopupComponent } from './accesspopup/accesspopup.component';
import { ValidatepopupComponent } from './validatepopup/validatepopup.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const isIE =
  window.navigator.userAgent.indexOf('MSIE') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ModalpopupComponent,
    SuccesspopupComponent,
    DownloadComponent,
    ErrorPopupComponent,
    AccesspopupComponent,
    ValidatepopupComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    FormsModule,
    MatDialogModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSnackBarModule,

    MsalModule.forRoot(
      new PublicClientApplication({
        // auth: {
        //   clientId: '756c643d-a273-4d72-9128-d4f4a6f980b2',
        //   redirectUri: environment.PostLogInUrl,
        //   authority:
        //     'https://login.microsoftonline.com/a69ed928-faab-4e4b-bafb-a6ecd5c9b5ef',
        // },
        auth: {
          clientId: '0063daa8-1ef2-4bbb-8993-c2c474f82390',
          redirectUri: environment.PostLogInUrl,
          authority:
            'https://login.microsoftonline.com/7d7d2b35-9c3e-43bd-b985-30895b172417',
        },

        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['User.Read'],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['User.Read']],
          // ['localhost',['https://localhost:44352/api']]
        ]),
      }
    ),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
    UploadService,
    AzureAdDemoService,
    SharedService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
