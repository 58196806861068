import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Commonconfig } from '../config/common-config';
import { UnzipService } from '../unzip.service';
import { ActivatedRoute } from '@angular/router';
import { AzureAdDemoService } from '../azure-ad-demo.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../shared.service';
import { MsalService } from '@azure/msal-angular';
import { AccesspopupComponent } from '../accesspopup/accesspopup.component';

declare function getRiskReport(params: any): any;

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})
export class DownloadComponent {
  public flag: boolean = false;
  isUserLoggedIn: boolean = false;
  public posts: Observable<String[]> | undefined;
  isAccessAllowed: boolean = false;
  userName: any;

  dialogref: any;
  count: number = 0;

  constructor(
    private http: HttpClient,
    public config: Commonconfig,
    public unzipService: UnzipService,
    public router: ActivatedRoute,
    private azureAdDemoService: AzureAdDemoService,
    private sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private authService: MsalService
  ) {}

  public documentname: any;
  public fileUrl: any;

  ngOnInit() {
    this.azureAdDemoService.isUserLoggedIn.subscribe((authenticationdata) => {
      this.sharedService.resetTimer();
      this.isUserLoggedIn = authenticationdata;

      const account = this.authService.instance.getAllAccounts()[0];
      if (account) {
        this.userName = account.username;
        localStorage.setItem('name', this.userName);

        this.unzipService.generateToken(this.userName).subscribe(
          (data) => {
            localStorage.setItem('token', data);
            this.unzipService.token = data;
            this.isAccessAllowed = true;
            let checking = localStorage.getItem('count');
            if (data && this.count === 0 && !checking) {
              window.location.reload();
              localStorage.setItem('count', 'set');
              this.count = 4;
            }
            this.myfunction(); // Call myfunction after the token is set
          },
          (err) => {
            this.dialogref.open(AccesspopupComponent);
          }
        );
      }
    });

    this.documentname = this.router.snapshot.queryParamMap.get('documentName');
  }

  myfunction() {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token is missing');
      return;
    }

    this.documentname = this.router.snapshot.queryParamMap.get('documentName');
    this.unzipService.download(this.documentname, token).subscribe(
      (data) => {
        this.sharedService.islogout = false;
        this.sharedService.resetTimer();

        const blob = new Blob([data], { type: 'application/octet-stream' });
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.documentname;
        this.fileUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(downloadURL);
        link.click();
        this.flag = true;
      },
      (err) => {
        console.error('Download failed', err);
      }
    );
  }
}
