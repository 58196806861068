<div *ngIf="popupservice.uploadStatus" class="mb-2">

  <div class="bar bar-overlay ">
    <div *ngIf="this.popupservice.progressInfos[this.popupservice.idx].value!=100 && this.popupservice.isexcelfile!=0 ">
      <h2 style="padding-right: 150px;margin: 0px 0 6px;" mat-dialog-title >File Upload</h2>
      <mat-divider></mat-divider>
      <br>
    </div>
    <div *ngIf="popupservice.uploadStatus === 'uploading'">
      <div *ngIf="popupservice.uploadCompletedFilesStatus === 'Completed'">
        <div *ngFor="let allSelectedFile of this.popupservice.allSelectedFiles" class="mb-2" style="display: flex;
        margin-left: 30px;
        
        flex-direction: column;">
    <mat-dialog-content class="fullprgsbar">
    
      {{ allSelectedFile.fileName }}
      
      <div *ngIf="allSelectedFile.completedstatus ==='Completed'">
        <span style="color:green">Completed</span>
      </div>
      <div *ngIf="allSelectedFile.completedstatus ==='error'">

        <span style="color:red">Not implemented</span>
      </div>
    
    </mat-dialog-content>
        </div>
      </div>
      <div *ngFor="let progressInfo of this.popupservice.progressInfos" class="mb-2" style="display: flex;
      margin-left: 30px;
      
      flex-direction: column;">

        <mat-dialog-content class="fullprgsbar" style="padding: 20px 30px 20px 24px;">
          {{ progressInfo.fileName }}

          <div *ngIf="progressInfo.value!=100 + '%'">

            <mat-progress-bar class="prgsbar" mode="indeterminate"></mat-progress-bar>
            <span style="color:red">pending....</span>

          </div>
          <div *ngIf="progressInfo.value==100 + '%'">

            <span style="color:green">Completed</span>
          </div>

        </mat-dialog-content>

      </div>
    </div>
    <div  class="popupmsg"  *ngIf="popupservice.uploadStatus === 'success'" >

      <mat-dialog-content style=" display: flex;
        justify-content: center;padding-left: 70px;
    padding-right: 70px;">
        Your File Uploaded Successfully
      </mat-dialog-content>
      <!-- <button class="btn" (click)="Close()">Ok</button> -->
    </div>

    <div  class="popupmsg"  *ngIf="popupservice.uploadStatus === 'failed'">
      <mat-dialog-content style=" display: flex;
        justify-content: center;">
       Uploaded Failed due to some technical issue. try again
      </mat-dialog-content>
      <button class="btn" (click)="Close()">Ok</button>
    </div>

    <div class="popupmsg" *ngIf="popupservice.uploadStatus === 'validexcelfilerequired'" >
      
      <mat-dialog-content style=" display: flex;
        justify-content: center;">

        Please upload Portfolio Look Through Holdings xls file 
      </mat-dialog-content>
      <button class="btn" (click)="Close()">Ok</button>
    </div>
  </div>
</div>