<div *ngIf="!_sharedService.isSessionExpired">
    <!-- <h2 style="margin: 0px 0 6px;" mat-dialog-title>PPT Creation</h2>
    <mat-divider></mat-divider> -->
    <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;padding-right: 70px;
    padding-left: 70px;
    padding-bottom: 20px;
">
    <mat-dialog-content class="msgshow">
        Technical error. Kindly contact your IT support team.
    </mat-dialog-content>

    <button tabindex="-1" mat-flat-button class="btn" (click)="Closepopup()">Ok</button>
  </div>
  </div>
  <div *ngIf="_sharedService.isSessionExpired">
    <!-- <h2 style="margin: 0px 0 6px;" mat-dialog-title>PPT Creation</h2>
    <mat-divider></mat-divider> -->
    <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;padding-right: 70px;
    padding-left: 70px;
">
    <mat-dialog-content class="msgshow" style="color: black;">
        Session Expired
    </mat-dialog-content>

   
  </div>
  </div>
  