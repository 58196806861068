import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss']
})
export class ErrorPopupComponent {
  constructor(private dialogref: MatDialog,public _sharedService:SharedService) { }
  Closepopup() {
    
    window.location.reload();

  }
}
