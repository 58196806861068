<div *ngIf="!_sharedService.isSessionExpired">
  <!-- <h2 style="margin: 0px 0 6px;" mat-dialog-title>PPT Creation</h2>
    <mat-divider></mat-divider> -->
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-right: 30px;
      padding-left: 30px;
      padding-bottom: 20px;
    "
  >
    <mat-dialog-content class="msgshow">
      {{ message }}
    </mat-dialog-content>

    <button
      *ngIf="!showPopup"
      tabindex="-1"
      mat-flat-button
      class="btn"
      (click)="Closepopup()"
    >
      Ok
    </button>
    <div class="d-flex">
      <button
        *ngIf="showPopup"
        tabindex="-1"
        mat-flat-button
        class="btn"
        style="width: 120px; margin-right: 10px"
        (click)="Closepopup()"
      >
        Re-Fetch
      </button>
      <button
        *ngIf="showPopup"
        tabindex="-1"
        mat-flat-button
        class="btn"
        style="width: 120px"
        (click)="onClickContinue()"
      >
        Proceed
      </button>
    </div>
  </div>
</div>
