<div class="bar bar-overlay">

  <div *ngIf="unzipservice.status==='pending'">

    <h2 style="margin: 0px 0 6px;" mat-dialog-title>PPT Creation</h2>
    <mat-divider></mat-divider>
    <div  style="display: flex;align-items: center;justify-content: center;flex-direction: column;padding-right: 70px;
    padding-left: 70px;
    padding-bottom: 25px;">
    <mat-dialog-content class="msgshow">
      Your PPT is being generated
    </mat-dialog-content>
    <br>
    <mat-progress-bar class="prgsbar" mode="indeterminate"></mat-progress-bar>
  </div>
  </div>
  <div *ngIf="unzipservice.status==='success'">
    <h2 style="margin: 0px 0 6px;" mat-dialog-title>PPT Creation</h2>
    <mat-divider></mat-divider>
    <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;padding-right: 70px;
    padding-left: 70px;
    padding-bottom: 20px;
">
    <mat-dialog-content class="msgshow">
      PPT has been sent to your Email
    </mat-dialog-content>

    <button tabindex="-1" mat-flat-button class="btn" (click)="Closepopup()">Ok</button>
  </div>
  </div>
  <div *ngIf="unzipservice.status==='failed'">
    <h2 style="margin: 0px 0 6px;" mat-dialog-title>PPT Creation</h2>
    <mat-divider></mat-divider>
    <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;padding-right: 70px;
    padding-left: 70px;
    padding-bottom: 20px;
">
    <mat-dialog-content class="msgshow">
      Generating PPT failed
    </mat-dialog-content>

    <button tabindex="-1" mat-flat-button class="btn" (click)="Closepopup()">Ok</button>
  </div>
  </div>

  <div *ngIf="unzipservice.status==='failed due to duplicate files'">
    <h2 style="margin: 0px 0 6px;" mat-dialog-title>PPT Creation</h2>
    <mat-divider></mat-divider>
    <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;padding-right: 70px;
    padding-left: 70px;
    padding-bottom: 20px;">
    <mat-dialog-content class="msgshow">
      Generating PPT failed,because {{this.popupservice.ppterrormessage}} source data is coming multiple times.
    </mat-dialog-content>

    <button tabindex="-1" mat-flat-button class="btn" (click)="Closepopup()">Ok</button>
  </div>
  </div>
</div>
