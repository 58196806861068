<div *ngIf="blankPage">
  <div style="padding: 1% 2rem">
    <div
      *ngIf="PPTStatus"
      style="width: 100%; margin: auto; border: 1px solid black"
      class="overlay"
    >
      <div class="dialog-box">
        <h2 mat-dialog-title>PPT Creation</h2>
        <mat-divider></mat-divider>
        <div class="dialog-content">
          <mat-dialog-content class="msgshow">
            {{ PPTCreation }}
          </mat-dialog-content>
          <br />
          <button
            tabindex="-1"
            mat-flat-button
            class="btn"
            (click)="Closepopup()"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>

  <div style="padding: 1% 2rem">
    <div
      *ngIf="PPTStatusError"
      class="overlay"
      style="width: 100%; margin: auto; border: 1px solid black"
    >
      <div class="dialog-box">
        <h2 mat-dialog-title>
          {{ PPTErrorTitle }}
        </h2>
        <mat-divider></mat-divider>
        <div class="dialog-content">
          <mat-dialog-content class="msgshow">
            {{ PPTCreationError }}
          </mat-dialog-content>
          <br />
          <!-- Additional Buttons -->
          <div *ngIf="showPopup" style="display: flex">
            <button
              tabindex="-1"
              mat-flat-button
              class="btn"
              style="width: 120px; margin-right: 10px"
              (click)="Closepopup()"
            >
              Re-Upload
            </button>
            <button
              tabindex="-1"
              mat-flat-button
              class="btn"
              style="width: 120px"
              (click)="onClickContinue()"
            >
              Proceed
            </button>
          </div>
          <!-- Existing Ok Button -->
          <button
            *ngIf="!showPopup"
            tabindex="-1"
            mat-flat-button
            class="btn"
            (click)="Closepopup()"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row col-md-7 mx-auto"
    style="
      border-style: groove;
      border-radius: 10px;
      margin-top: 16px;
      border: 2px solid #999090;
      box-shadow: 0 0 4px 4px #a3b6b6;
      min-height: 55%;
    "
  >
    <div style="height: 500px">
      <div class="col-md-11 mx-auto">
        <div class="form-group row" style="padding-top: 40px">
          <div class="col-sm-4" style="padding-bottom: 30px">
            <label for="inputAmount display " style="font-size: large"
              >Amount display</label
            >
            <div class="form-control" style="border: 2px solid #b8b9bb">
              <select class="box" #ref (change)="getSelectedValue(ref.value)">
                <option value="Actual">Actual</option>
                <option value="Lakhs">Lakhs</option>
                <option value="Crores">Crores</option>
                <option value="Millions">Millions</option>
              </select>
            </div>
          </div>
          <div class="col-sm-4" style="padding-bottom: 30px">
            <label for="inputDecimal Precision " style="font-size: large"
              >Decimal Precision</label
            >
            <div
              class="form-control"
              style="border: 2px solid #b8b9bb"
              (change)="getSelecteddecimalValue()"
            >
              <input
                type="radio"
                [(ngModel)]="radioSelected1"
                name="options"
                value="0"
              />0
              <input
                type="radio"
                [(ngModel)]="radioSelected1"
                name="options"
                value="2"
              />2
            </div>
          </div>
          <div class="col-sm-4" style="padding-bottom: 30px">
            <label for="inputDecimal Precision " style="font-size: large"
              >Annexure</label
            >
            <div
              class="form-control"
              style="border: 2px solid #b8b9bb"
              (change)="getSelectedannexureValue()"
            >
              <input
                type="radio"
                [(ngModel)]="radioSelected2"
                name="annexure"
                value="Include"
                [disabled]="excludeRadioDisabled"
              />Include
              <input
                type="radio"
                [(ngModel)]="radioSelected2"
                name="annexure"
                value="Exclude"
              />Exclude
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-11 form-group row mx-auto">
        <div
          class="col-sm-12 col-md-3 col-lg-3"
          style="width: min-content; padding: 0px"
        >
          <label for="HeldAwayType" style="font-size: large"
            >Portfolio Type</label
          >
          <div class="form-control" style="border: 2px solid #b8b9bb">
            <select
              class="box"
              #ref1
              (change)="getSelectedHeldAway(ref1.value)"
            >
              <option value="" disabled selected>Select Type</option>
              <option value="ExcludeHeldAway">Exclude Held Away</option>
              <option value="IncludeHeldAway">Include Held Away</option>
            </select>
          </div>
        </div>
      </div>
      <div (click)="ShowHideFunction()">
        <div
          class="drop-down-container"
          style="margin-bottom: 30px"
          *ngIf="familyHideValue"
        >
          <div class="drop-down-sub-container">
            <label for="inputAmount display" style="font-size: 16px"
              >Family Name:</label
            >
            <div class="drop-down-box">
              <select
                class="box"
                [(ngModel)]="selectedFamilyName"
                (change)="getSelectValue($event)"
              >
                <option value="" disabled selected>Select Family Name</option>
                <option *ngFor="let item of familyNames" [value]="item.groupId">
                  {{ item?.groupName }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div
          class="drop-down-container"
          style="margin-bottom: 30px"
          *ngIf="fromDateValue"
        >
          <div class="from-date">
            <label for="from-date" style="font-size: 16px">From Date :</label>
            <input
              type="date"
              id="from-date"
              name="from-date"
              class="date-box"
              [(ngModel)]="fromDate"
              (change)="validateDates()"
              [max]="currentDate"
            />
          </div>
          <div class="To-date">
            <label for="to-date" style="font-size: 16px">To Date :</label>
            <input
              type="date"
              id="to-date"
              name="to-date"
              class="date-box"
              [(ngModel)]="toDate"
              (change)="validateDates()"
              [max]="currentDate"
            />
            <div *ngIf="dateError" style="color: red">
              From date should not be greater than to date
            </div>
          </div>
        </div>

        <div
          class="drop-down-container"
          *ngIf="asOnDateValue"
          style="margin-bottom: 30px"
        >
          <div class="from-date">
            <label for="from-date" style="font-size: 16px">As On Date :</label>
            <input
              class="drop-down-box"
              type="date"
              id="from-date"
              name="from-date"
              [(ngModel)]="asOnDate"
              [max]="currentDate"
            />
          </div>
        </div>
        <!-- dashboard.component.html -->
        <div *ngIf="loading" class="loader-overlay">
          <div class="loader-content">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            <div class="loader-message">PPT is Generating...</div>
          </div>
        </div>

        <div class="col-md-3 mx-auto py-2 upload-btn" *ngIf="generateButton">
          <div class="col-sm-12">
            <div class="aboveframe" style="padding: 0px; padding-left: 20px">
              <button
                class="allbuttons"
                (click)="isHandleClick()"
                [disabled]="!isFormValid()"
                [ngClass]="{ disabled: !isFormValid() }"
              >
                Process and Generate PPT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
