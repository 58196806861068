import { Component, OnInit } from '@angular/core';
import { AzureAdDemoService } from '../azure-ad-demo.service';
import { UploadService } from 'src/app/upload.service';
import { UnzipService } from '../unzip.service';
import {
  HttpEventType,
  HttpResponse,
  HttpClient,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { SharedService } from '../shared.service';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ModalpopupComponent } from '../modalpopup/modalpopup.component';
import { PopupService } from '../popup.service';
import { SuccesspopupComponent } from '../successpopup/successpopup.component';
import { Commonconfig } from '../config/common-config';
import { AccesspopupComponent } from '../accesspopup/accesspopup.component';
import { MsalService } from '@azure/msal-angular';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ValidatepopupComponent } from '../validatepopup/validatepopup.component';
import { integer } from 'aws-sdk/clients/cloudfront';
import { ErrorPopupComponent } from '../error-popup/error-popup.component';
import { TodoService } from '../drop-down.service';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Payload } from 'aws-sdk/clients/iotdata';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  portfolioType: string = 'ExcludeHeldAway'; // Default value
  dropdownData: any[] = [];
  addedTodo: any;
  advisorName: any = '';
  familyNames: any[] = [];
  fromDate: string = '';
  toDate: string = '';
  asOnDate: string = '';
  dateError: boolean = false;
  // advisorName: string = 'Rohit Laungani';
  selectedGroupId: string | null = '';
  selectedFamilyName: string = '';

  isUserLoggedIn: boolean = false;
  isAccessAllowed: boolean = false;
  selectedFiles: any;
  message = '';
  filePassedCount: any = 0;
  fileFailedCount: any = 0;
  fileInfos: Observable<any> | null = null;
  messageIndex: number = 0;
  showPortfolioItem: boolean = false;
  familyHideValue: boolean = false;
  generateButton: boolean = false;
  fromDateValue: boolean = false;
  toDateValue: boolean = false;
  asOnDateValue: boolean = false;
  adusername: string = '';
  userName: any;
  public errormsg: any;
  public contentType: any;
  currentdate = new Date();
  currentdateandtime = '';
  currentDate: string = '';
  loading: boolean = false;
  SelectFamilyName: string = 'Select a family name';
  //Snackbar Alternative Methods
  PPTStatus: boolean = false;
  PPTCreation: string = '';
  PPTStatusError: boolean = false;
  PPTCreationError: string = '';
  PPTErrorTitle: string = 'PPT Creation'; // Default title
  showPopup: boolean = false;
  requestId: string | null = null; // Variable to store requestId
  isSoftButton: boolean = false;
  blankPage: boolean = false;
  count: number = 0;

  readonly TokenUrl = this.config.Tokenapiurl;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: MsalService,
    private azureAdDemoService: AzureAdDemoService,
    private uploadService: UploadService,
    private sharedService: SharedService,
    private unzipService: UnzipService,
    private httpClient: HttpClient,
    private dialogref: MatDialog,
    public popupService: PopupService,
    public config: Commonconfig,
    private _dialog: MatDialog,
    private todoService: TodoService,
    private snackbar: MatSnackBar
  ) {
    this.currentdateandtime = formatDate(
      this.currentdate,
      'ddMMyyyyhhmmssa',
      'en-US',
      '+0530'
    );
    this.amtdisplayselectedvalues = this.dropdownselected;
    this.decimalselectedvalues = this.radioSelected1;
    this.anexxureselectedvalues = this.radioSelected2;
    this.setCurrentDate();
  }

  token: any = localStorage.getItem('token');
  userData: any = localStorage.getItem('userdata');
  emailID: any = localStorage.getItem('emailId');

  ngOnInit(): void {
    this.azureAdDemoService.isUserLoggedIn.subscribe((authenticationdata) => {
      this.isUserLoggedIn = authenticationdata;
      this.userName = localStorage.getItem('username');

      localStorage.setItem(
        'name',
        this.authService.instance.getAllAccounts()[0].username
      );
      const name = localStorage.getItem('name');

      this.unzipService.generateToken(this.userName).subscribe(
        (data) => {
          localStorage.setItem('token', data);
          this.unzipService.token = localStorage.getItem('token');
          this.blankPage = localStorage.getItem('token') ? true : false;
          this.isAccessAllowed = true;
          let checking = localStorage.getItem('count');
          if (data && this.count === 0 && !checking) {
            window.location.reload();
            localStorage.setItem('count', 'set');
            this.count = 4;
          }
          // console.log(this.count, 'ffffffffffff', data && this.count == 0);
        },
        (err) => {
          this.dialogref.open(AccesspopupComponent);
        }
      );
    });
    this.sharedService.sharedData$.subscribe(
      (sharedData) => (this.adusername = sharedData)
    );

    this.sharedService.resetTimer();

    this.advisorName = this.authService.instance.getAllAccounts()[0].name;
    // this.fetchFamilyNames();

    this.router.events.subscribe(() => {
      const currentPath = this.router.url;
      console.log('Current Path:', currentPath?.substring(7));
    });
  }

  // Date Validation
  Closepopup() {
    this.PPTStatus = false;
    this.PPTStatusError = false;
    this.PPTErrorTitle = 'PPT Creation';
    this.requestId = null;
    window.location.reload(); // Page is reloaded
  }

  setCurrentDate(): void {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');

    this.currentDate = `${yyyy}-${mm}-${dd}`;
  }

  validateDates(): void {
    if (this.fromDate && this.toDate) {
      const fromDate = new Date(this.fromDate);
      const toDate = new Date(this.toDate);
      this.dateError = toDate < fromDate;
    } else {
      this.dateError = false;
    }
  }

  isFormValid(): boolean {
    if (this.selectedGroupId !== '' && this.selectedFamilyName !== '') {
      if (this.portfolioType === 'IncludeHeldAway') {
        return !!this.asOnDate && !this.dateError;
      } else {
        return !!this.fromDate && !!this.toDate && !this.dateError;
      }
    }
    return false;
  }

  //Drop Down List

  fetchFamilyNames(portfolioTypes: boolean): void {
    this.todoService.getFamilyNames(this.advisorName, portfolioTypes).subscribe(
      (response) => {
        console.log(response, 'DDD');
        if (response.status === 200) {
          this.familyNames = response.data;
          console.log(this.familyNames[0].groupId, 'Hello');
          if (this.familyNames.length > 0) {
            this.selectedGroupId = this.familyNames[0].groupId;
          }
        } else {
          console.error('Error fetching family names:', response.message);
        }
      },
      (error) => {
        console.error('Error fetching family names:', error);
        let errorMessage = error.error.message;
        // Check for specific error statuses
        if (error.status === 502) {
          errorMessage = 'Insight API Not Available';
          this.PPTErrorTitle = 'Service Down';
        } else if (error.status === 0) {
          errorMessage = 'Insight API Not Available';
          this.PPTErrorTitle = 'Service Down';
        } else if (error.message && error.message.includes('CORS')) {
          errorMessage = 'CORS error. Please check your server configuration.';
          this.PPTErrorTitle = 'CORS Error';
        }
        this.PPTErrorTitle = 'Alert';
        this.PPTCreationError = errorMessage;
        this.PPTStatusError = true;
      }
    );
  }

  // Dropdown Functionality

  getSelectValue(event: Event): void {
    const target = event.target as HTMLSelectElement;
    if (target) {
      const value = target.value;
      console.log(value, '456');
      this.selectedGroupId = value;
      this.selectedFamilyName = value;
    }
  }

  selectFiles(e: any): void {
    this.popupService.progressInfos = [];
    this.popupService.uploadStatus = '';
    this.selectedFiles = e.target.files;
  }

  public pptfilename: any;
  public amtdisplayselectedvalues: any;
  public heldawayvalue: any = 'ExcludeHeldAway';
  public excludeRadioDisabled: boolean = false;
  public decimalselectedvalues: any;
  public anexxureselectedvalues: any;
  public heldawayselectedvalues: any;
  radioSelected1: any = '0';
  radioSelected2: any = 'Include';
  dropdownselected: any = 'Actual';
  public validexcelfile: any = 0;
  public filesuploadedcount: any;
  public filesremainingcount: any;
  public setNumber: any = 0;

  // public xlsfiles: any=0;

  getSelectedValue(value: any) {
    // Prints selected value
    this.amtdisplayselectedvalues = value;
    if (this.amtdisplayselectedvalues == 'Actual') {
      this.radioSelected1 = '0';
      this.decimalselectedvalues = this.radioSelected1;
    } else {
      this.radioSelected1 = '2';
      this.decimalselectedvalues = this.radioSelected1;
    }
  }

  getSelectedHeldAway(value: any) {
    this.portfolioType = value;
    this.heldawayvalue = value;
    // Reset family names and dates
    this.selectedGroupId = '';
    this.selectedFamilyName = '';
    this.asOnDate = '';
    this.fromDate = '';
    this.toDate = '';
    this.familyNames = [];

    if (value == 'IncludeHeldAway') {
      this.radioSelected2 = 'Exclude';
      this.heldawayselectedvalues = this.radioSelected2;
      this.excludeRadioDisabled = true;
      this.familyHideValue = true;
      this.generateButton = true;
      this.asOnDateValue = true;
      this.fromDateValue = false;
      this.toDateValue = false;
      this.fetchFamilyNames(false);
    } else if (value == 'ExcludeHeldAway') {
      this.radioSelected2 = 'Include';
      this.heldawayselectedvalues = this.radioSelected2;
      this.excludeRadioDisabled = false;
      this.familyHideValue = true;
      this.generateButton = true;
      this.fromDateValue = true;
      this.toDateValue = true;
      this.asOnDateValue = false;
      this.fetchFamilyNames(true);
    }

    // this.fetchFamilyNames();
  }

  ShowHideFunction() {
    if (
      this.portfolioType == 'ExcludeHeldAway' ||
      this.portfolioType == 'IncludeHeldAway'
    ) {
      this.showPortfolioItem = true;
    }
  }

  getSelecteddecimalValue() {
    this.decimalselectedvalues = this.radioSelected1;
  }

  getSelectedannexureValue() {
    this.anexxureselectedvalues = this.radioSelected2;
  }
  // Proceed Call
  onClickContinue() {
    console.log('onClickContinue');
    this.loading = true;

    // Check if the popup is already shown
    if (this.showPopup) {
      // Close the popup and reset the state before proceeding
      this.showPopup = false;
      this.PPTCreationError = '';
      this.PPTStatusError = false;
    }

    this.isGenerateButtonClick(this.requestId);
  }

  isHandleClick() {
    if (this.portfolioType == 'ExcludeHeldAway') {
      this.isGenerateButtonClick();
    } else {
      this.excelvalidation();
    }
  }

  // ExcelValidation(): void {
  //   this.excelvalidation();
  // }

  isGenerateButtonClick(requestId?: string | null): void {
    if (!this.selectedGroupId) {
      console.error('No group ID selected');
      return;
    }

    // Adjust dates based on heldaway value
    let fromDate = this.fromDate;
    let toDate = this.toDate;
    let asOnDate = this.asOnDate;

    interface Payload {
      // requestId?: string | null; // Allow requestId to be null
      groupId: string;
      fromDate?: string;
      toDate?: string;
      asOnDate?: string;
      heldAway: string;
      amtDisplay: any;
      precision: any;
      annxure: any;
      email: string;
    }

    const payload: Payload = {
      groupId: this.selectedGroupId.toString(),
      heldAway: this.heldawayvalue,
      amtDisplay: this.amtdisplayselectedvalues,
      precision: this.decimalselectedvalues,
      annxure: this.radioSelected2,
      email: this.sharedService.email,
    };
    console.log(payload, this.userName, this.sharedService.email, 'email');

    // Conditionally add dates to the payload
    if (this.heldawayvalue === 'IncludeHeldAway') {
      // payload.requestId = this.requestId; // Use stored requestId
      payload.asOnDate = asOnDate;
      console.log(asOnDate);
    } else {
      payload.fromDate = fromDate;
      payload.toDate = toDate;
    }
    this.loading = true; // Start loading
    this.sharedService.islogout = false;
    this.sharedService.resetTimer();

    console.log(payload, 'groupId', this.fromDate, this.toDate);
    this.todoService
      .generateReport(payload)
      .subscribe(
        (response) => {
          if (response.status === 200) {
            // Handle success
            this.PPTCreation =
              response.message || 'Report generated successfully';
            this.PPTStatus = true;
            this.sharedService.islogout = false;
            this.sharedService.resetTimer();
          } else {
            // Handle unexpected response statuses
            this.PPTCreationError =
              response.message || 'The PPT generation is incomplete';
            this.PPTStatusError = true;
            this.PPTErrorTitle = 'PPT Creation Error';
          }
        },
        (error) => {
          // Handle errors
          console.error('Error generating report:', error);
          let errorMessage = 'Error generating report';

          // Check for specific error statuses
          if (error.status === 502) {
            errorMessage = 'Page Not Found';
            this.PPTErrorTitle = 'Server Error';
          } else if (error.status === 0) {
            errorMessage = 'Page Not Found';
            this.PPTErrorTitle = 'Server Error';
          } else if (error.message && error.message.includes('CORS')) {
            errorMessage =
              'CORS error. Please check your server configuration.';
            this.PPTErrorTitle = 'CORS Error';
          } else if (
            error.status === 400 &&
            error.error &&
            error.error.message
          ) {
            errorMessage = error.error.message;
            this.PPTErrorTitle = 'Input Error';

            if (this.heldawayvalue === 'IncludeHeldAway') {
              // this.showPopup = true; // Show popup for status code 400
              this.showPopup = error.error.data.isSoft;
              // this.showPopup === error.error.data.isSoft ? true : false;
              this.PPTCreationError = errorMessage;
              this.PPTStatusError = true;
              this.requestId = error.error.data.id || null; // Store the requestId
              this.loading = false; // Stop loading when error occurs
              console.log(this.requestId, 'requestId');
              this.sharedService.islogout = false;
              this.sharedService.resetTimer();
              return;
            }
          }

          this.PPTCreationError = errorMessage;
          this.PPTStatusError = true;
          this.sharedService.islogout = false;
          this.sharedService.resetTimer();
        }
      )
      .add(() => {
        this.loading = false;
        this.sharedService.islogout = false;
        this.sharedService.resetTimer();
      });
  }

  excelvalidation() {
    // this.popupService.uploadStatus = 'uploading';
    if (!this.selectedGroupId) {
      console.error('No group ID selected');
      return;
    }
    this.currentdate = new Date();
    this.currentdateandtime = formatDate(
      this.currentdate,
      'ddMMyyyyhhmmssa',
      'en-US',
      '+0530'
    );
    // const hardcodedFile = new File([''], 'Holding (30).xlsx', {
    //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // });

    // Assign this hardcoded file to this.selectedFiles array
    // this.selectedFiles = [hardcodedFile];
    // this.selectedFiles = [hardcodedFile];

    // this.dialogref.open(ModalpopupComponent);
    // for (let i = 0; i < this.selectedFiles.length; i++) {
    // this.popupService.isexcelfile++;
    // this.popupService.progressInfos[this.popupService.idx] = {
    //   value: 0,
    //   fileName: this.selectedFiles[i].name,
    // };
    // if (this.selectedFiles[i].name.split('.').pop().includes('xls', 'xlsx')) {
    // this.popupService.isexcelfile++;
    if (this.heldawayvalue === 'IncludeHeldAway') {
      // if (this.selectedFiles?.length > 1) {
      //   const details = {
      //     showPopup: false,
      //     popupMessageShown:
      //       'Kindly upload ONLY relevant Look Through Holding -Xls file for the generation of PPT in Include HeldAway Template.',
      //   };
      //   const dialogRef = this._dialog.open(ValidatepopupComponent, {
      //     data: details,
      //     scrollStrategy: new NoopScrollStrategy(),
      //   });
      //   dialogRef.afterClosed().subscribe((resp) => {
      //     window.location.reload();
      //   });
      // }
      // else {
      let asOnDate = this.asOnDate;

      const payload: Payload = {
        groupId: this.selectedGroupId.toString(),
        asOnDate: asOnDate,
        email: this.sharedService.email,
      };
      this.loading = true;

      this.unzipService.HeldAwayValidatePPT(payload).subscribe(
        ({ needConfirmation, message }) => {
          this.sharedService.islogout = false;
          this.sharedService.resetTimer();
          const details = {
            showPopup: needConfirmation,
            popupMessageShown: message[0],
          };
          if (!needConfirmation) {
            const details = {
              showPopup: needConfirmation,
              popupMessageShown: message[0],
            };
            const dialogRef = this._dialog.open(ValidatepopupComponent, {
              data: details,
              scrollStrategy: new NoopScrollStrategy(),
            });
            dialogRef.afterClosed().subscribe((resp) => {
              window.location.reload();
            });
          }
          if (needConfirmation && message.length !== 0) {
            const openDialogSequentially = (index: number) => {
              if (index >= message.length) {
                return; // Stop recursion if all messages have been processed
              }

              const detail = {
                showPopup: needConfirmation,
                popupMessageShown: message[index],
              };

              const dialogRef = this._dialog.open(ValidatepopupComponent, {
                data: detail,
                scrollStrategy: new NoopScrollStrategy(),
              });

              dialogRef.afterClosed().subscribe((resp) => {
                if (resp) {
                  console.log(resp, 'detail');
                  if (index === message.length - 1) {
                    this.messageIndex = 0;
                    this.validexcelfile = 1;
                    this.userfiles = '';
                    // this.s3excelfileupload();
                    this.isGenerateButtonClick();
                  } else {
                    openDialogSequentially(index + 1);
                  } // Open the next dialog if response is true
                } else {
                  window.location.reload(); // Reload the page if response is false
                }
              });
            };

            // Start opening dialogs from the first message
            openDialogSequentially(0);
          }
          this.loading = false;
          if (needConfirmation == true && message.length === 0) {
            this.validexcelfile = 1;
            this.userfiles = '';
            this.isGenerateButtonClick();
          } else {
            this.sharedService.islogout = false;
            this.sharedService.resetTimer();
          }
        },
        (err) => {
          this.sharedService.islogout = false;
          this.sharedService.resetTimer();
          this.loading = false;
        }
      );
      // this.loading = false;
      // }
    }
    // }
    // else {
    //   if (
    //     this.popupService.isexcelfile <= this.selectedFiles.length &&
    //     i == this.selectedFiles.length - 1
    //   ) {
    //     this.popupService.uploadStatus = 'validexcelfilerequired';
    //   } else if (
    //     this.validexcelfile >= 1 &&
    //     i == this.selectedFiles.length - 1
    //   ) {
    //     this.s3excelfileupload();
    //   }
    // }
    // }
    // this.loading = false;
  }
  // file uploading

  s3excelfileupload(): void {
    this.popupService.uploadStatus = 'uploading';
    this.filesuploadedcount = 0;
    this.message = '';
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.popupService.idx = i;
      this.popupService.progressInfos[this.popupService.idx] = {
        value: 0,
        fileName: this.selectedFiles[i].name,
        status: '',
      };
      console.log(this.selectedFiles[i].name.split('.').pop());
      if (this.selectedFiles[i].name.split('.').pop().includes('xls', 'xlsx')) {
        this.popupService.progressInfos[this.popupService.idx] = {
          value: 0,
          fileName: this.selectedFiles[i].name,
          status: '',
        };
        const filename =
          this.adusername +
          '_' +
          this.currentdateandtime +
          this.selectedFiles[i].name;
        this.popupService.passfilename = filename;
        var file = new File(this.selectedFiles, 'filename');
        this.contentType = file.type;
        this.userfiles = filename;
        this.unzipService
          .S3excelupload(this.selectedFiles[i], filename)
          .subscribe(
            (data) => {
              console.log('********************************', data.message);
              this.sharedService.islogout = false;
              this.sharedService.resetTimer();
              if (data.message == true) {
                this.popupService.progressInfos[i].value = 100 + '%';
                this.filesuploadedcount++;
                console.log('Successfully uploaded file.', filename);
                if (this.heldawayvalue === 'IncludeHeldAway') {
                  this.unzipService
                    .mfitlog('0', 'Success')
                    .subscribe((res) => {});
                }
                if (this.selectedFiles.length === 1) {
                  this.popupService.allSelectedFiles[
                    this.popupService.completedidx
                  ] = {
                    fileName: this.selectedFiles[i].name,
                    completedstatus: 'Completed',
                  };
                  this.popupService.uploadStatus = 'success';
                  this.popupService.uploadCompletedFilesStatus = 'Completed';
                  this.popupService.completedidx++;
                  setTimeout(() => {
                    this.ppt();
                  }, 4000);
                }
              }
            },
            (err) => {
              this.sharedService.islogout = false;
              this.sharedService.resetTimer();
            }
          );
      } else {
        if (this.validexcelfile == null) {
          this.popupService.uploadStatus = 'validexcelfilerequired';
        } else {
          this.upload(i, this.selectedFiles[i]);
        }
      }
    }
  }
  public userfiles: string = '';

  upload(idx: any, file: any): void {
    this.popupService.progressInfos[this.popupService.idx] = {
      value: 0,
      fileName: file.name,
      status: '',
    };

    try {
      this.uploadService.upload(file).subscribe(
        (res) => {
          this.sharedService.islogout = false;
          this.sharedService.resetTimer();

          let event: any = res;
          if (res.type === HttpEventType.UploadProgress) {
            this.popupService.progressInfos[idx].value = 20 * res.type + '%';
          } else if (res.type === 4) {
            this.popupService.progressInfos[idx].value = 20 * res.type + '%';
            const blob = new Blob([event.body], {
              type: 'application/octet-stream',
            });
            const filename =
              this.adusername +
              '_' +
              this.currentdateandtime +
              'set' +
              this.setNumber +
              '.zip';

            this.setNumber++;
            this.filePassedCount++;
            if (
              this.filePassedCount + this.fileFailedCount ===
              this.selectedFiles?.length - 1
            ) {
              this.unzipService
                .mfitlog(this.filePassedCount, 'Success')
                .subscribe((res) => {});
              this.unzipService
                .mfitlog(this.fileFailedCount, 'Failed')
                .subscribe((res) => {});
            }
            this.popupService.passfilename = filename;
            if (this.userfiles == '') {
              this.userfiles = filename;
            } else {
              this.userfiles = this.userfiles + ';' + filename;
            }
            console.log(this.userfiles);
            var file = new File([blob], 'filename');
            this.contentType = file.type;
            this.s3upload(idx, file, filename);
          }
        },
        (err) => {
          this.setNumber++;
          const filename =
            this.adusername +
            '_' +
            this.currentdateandtime +
            'set' +
            this.setNumber +
            '.zip';
          console.log('error set', filename);

          this.fileFailedCount++;
          if (
            this.filePassedCount + this.fileFailedCount ===
            this.selectedFiles?.length - 1
          ) {
            this.unzipService
              .mfitlog(this.filePassedCount, 'Success')
              .subscribe((res) => {});
            this.unzipService
              .mfitlog(this.fileFailedCount, 'Failed')
              .subscribe((res) => {});
          }
        }
      );
    } catch {
      this.sharedService.islogout = false;
      this.sharedService.resetTimer();
      this.popupService.uploadStatus = 'failed';
    }
  }

  //uploading file in S3bucket

  s3upload(idx: any, s3file: any, s3filename: any) {
    const bucket = new S3({
      accessKeyId: this.config.S3Accesskeyid,
      secretAccessKey: this.config.S3SecretaccessKey,
      region: 'ap-south-1',
    });
    const params = {
      Bucket: this.config.S3bucket,
      Key:
        this.config.s3Folder != null
          ? this.config.s3Folder + '/' + s3filename
          : '/' + s3filename,
      Body: s3file,
      // ACL: 'public-read',
      ContentType: this.contentType,
    };

    // made changes may15

    this.unzipService.S3fileupload(s3file, s3filename).subscribe((data) => {
      // bucket.upload(params, (err: any, data: any) => {

      this.filesremainingcount =
        this.selectedFiles.length - this.filesuploadedcount;

      if (!data.message) {
        console.log('There was an error uploading your file: ');
        return false;
      }
      if (s3filename.split('.').pop() !== 'xlsx' || 'xls') {
        this.unzipService.unzipfiles(s3filename).subscribe(
          (data) => {
            this.sharedService.islogout = false;
            this.sharedService.resetTimer();
            console.log('********************************', data);
          },
          (err) => {
            this.sharedService.islogout = false;
            this.sharedService.resetTimer();
          }
        );
        this.popupService.progressInfos[idx].value = 100 + '%';
        //console.log('Successfully uploaded file.', data);
      } else {
        this.popupService.progressInfos[idx].value = 100 + '%';
        //console.log('Successfully uploaded file.', data);
      }

      if (this.filesremainingcount == 1) {
        this.popupService.uploadStatus = 'success';
        this.popupService.uploadCompletedFilesStatus = 'Completed';
        setTimeout(() => {
          this.ppt();
        }, 4000);
        for (let i = 0; i < this.selectedFiles.length; i++) {
          if (this.popupService.progressInfos[idx].status === 'error') {
            this.popupService.allSelectedFiles[this.popupService.completedidx] =
              {
                fileName: this.selectedFiles[i].name,
                completedstatus: 'error',
              };
            this.popupService.completedidx++;
          } else {
            this.popupService.allSelectedFiles[this.popupService.completedidx] =
              {
                fileName: this.selectedFiles[i].name,
                completedstatus: 'Completed',
              };
            this.popupService.completedidx++;
          }
        }
      } else {
        this.filesuploadedcount++;
      }
      return true;
    });
  }

  // Generating PPT

  ppt() {
    this.unzipService.status = 'pending';
    this.openpopup();
    this.pptfilename = this.adusername + '_' + this.currentdateandtime;
    if (this.heldawayvalue == 'IncludeHeldAway') {
      this.unzipService
        .heldawaygeneratereport(
          this.userfiles,
          this.pptfilename,
          this.amtdisplayselectedvalues,
          this.decimalselectedvalues,
          this.heldawayselectedvalues,
          this.sharedService.email,
          this.heldawayvalue
        )
        .subscribe(
          (data: any) => {
            this.sharedService.islogout = false;
            this.sharedService.resetTimer();

            console.log('*******************', data);
            this.unzipService.status = 'success';
            this.selectedFiles = null;
            this.validexcelfile = null;
            this.popupService.uploadCompletedFilesStatus = 'NotCompleted';
            this.popupService.completedidx = 0;
            this.popupService.allSelectedFiles = [];
            this.setNumber = 0;
          },
          (err) => {
            console.log({ err });
            this.sharedService.islogout = false;
            this.sharedService.resetTimer();
            this.selectedFiles = null;
            //---------------changed------------------------------------------
            this.errormsg = err.error;
            if (this.errormsg.includes('already belongs to this DataSet')) {
              this.unzipService.status = 'failed due to duplicate files';
              this.popupService.ppterrormessage = err.error.substring(19, 24);
            } else {
              this.unzipService.status = 'failed';
            }
            //-----------------------------------------------------------------
          }
        );
    } else {
      this.unzipService
        .generateppt(
          this.userfiles,
          this.pptfilename,
          this.amtdisplayselectedvalues,
          this.decimalselectedvalues,
          this.anexxureselectedvalues,
          this.sharedService.email,
          this.heldawayvalue
        )
        .subscribe(
          (data: any) => {
            this.sharedService.islogout = false;
            this.sharedService.resetTimer();

            console.log('*******************', data);
            this.unzipService.status = 'success';
            this.selectedFiles = null;
            this.validexcelfile = null;
            this.popupService.uploadCompletedFilesStatus = 'NotCompleted';
            this.popupService.completedidx = 0;
            this.popupService.allSelectedFiles = [];
            this.setNumber = 0;
          },
          (err) => {
            console.log({ err });
            this.sharedService.islogout = false;
            this.sharedService.resetTimer();
            this.selectedFiles = null;
            //---------------changed------------------------------------------
            this.errormsg = err.error;
            if (this.errormsg.includes('already belongs to this DataSet')) {
              this.unzipService.status = 'failed due to duplicate files';
              this.popupService.ppterrormessage = err.error.substring(19, 24);
            } else {
              this.unzipService.status = 'failed';
            }
            //-----------------------------------------------------------------
          }
        );
    }
  }
  openpopup() {
    this.dialogref.open(SuccesspopupComponent);
  }
}
